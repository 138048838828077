* {
  padding: 0;
  margin: 0;
}

input:focus {
  outline: 0;
}

a,
a:focus,
a:visited {
  text-decoration: none;
}


#root-watchlist > div {
  display: flex;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: cyan;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}